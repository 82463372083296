import Axios from "axios";

export const issuingReportApi = ({
	userId,
	merchantId,
	memberName,
	userName,
	refId,
	startDate,
	endDate,
	pageSize,
	page,
	authToken,
	deviceId,
	pointType,
	availableDate,
	orderBy,
	orderType
}) => {
	if (authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (page > 0) {
			mulai = pageSize + 1;
			baris = 0;
			for (var i = 0; i <= page; ) {
				baris = pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/issuing",
			params: {
				memberId: userId,
				merchantId: merchantId,
				memberName: memberName,
				username: userName,
				refId: refId,
				startDate: startDate,
				endDate: endDate,
				offset: mulai,
				pageSize: pageSize,
				page: page,
				nRecords: pageSize,
				pointType: pointType,
				availableDate: availableDate,
				orderBy: orderBy,
				orderType: orderType
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const redeemReportApi = ({
	userId,
	merchantId,
	memberName,
	userName,
	transactionId,
	startDate,
	endDate,
	page,
	pageSize,
	authToken,
	deviceId,
}) => {
	if (authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (page > 0) {
			mulai = pageSize + 1;
			baris = 0;
			for (var i = 0; i <= page; ) {
				baris = pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/redeem",
			params: {
				memberId: userId,
				merchantId: merchantId,
				memberName: memberName,
				username: userName,
				transactionId: transactionId,
				startDate: startDate,
				endDate: endDate,
				offset: mulai,
				pageSize: pageSize,
				page: page,
				nRecords: pageSize,
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const memberBalanceApi = (data) => {
	if (data.authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (data.page > 0) {
			mulai = data.pageSize + 1;
			baris = 0;
			for (var i = 0; i <= data.page; ) {
				baris = data.pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/member-balance",
			params: {
				memberId: data.userId,
				merchantId: data.merchantId,
				memberName: data.memberName,
				username: data.userName,
				startDate: data.startDate,
				endDate: data.endDate,
				offset: mulai,
				pageSize: data.pageSize,
				page: data.page,
				nRecords: data.pageSize,
			},
			data: {},
			headers: {
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const voucherBalanceApi = (data) => {
	if (data.authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (data.page > 0) {
			mulai = data.pageSize + 1;
			baris = 0;
			for (var i = 0; i <= data.page; ) {
				baris = data.pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/voucher-balance",
			params: {
				memberId: data.userId,
				merchantId: data.merchantId,
				memberName: data.memberName,
				productName: data.productName,
				username: data.userName,
				transactionId: data.transactionId,
				status: data.status,
				offset: mulai,
				pageSize: data.pageSize,
				page: data.page,
				nRecords: data.pageSize,
				isOwnProduct: data.isOwnProduct,
				statusList: data.statusList,
				voucherId: data.voucherId,
				voucherCode: data.voucherCode,
				isVoucherCodeEncrypt: data.isVoucherCodeEncrypt
			},
			data: {},
			headers: {
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const referralReportApi = (data) => {
	if (data.authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (data.page > 0) {
			mulai = data.pageSize + 1;
			baris = 0;
			for (var i = 0; i <= data.page; ) {
				baris = data.pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/referral-member",
			params: {
				memberId: data.userId,
				merchantId: data.merchantId,
				memberName: data.memberName,
				username: data.userName,
				startDate: data.startDate,
				endDate: data.endDate,
				offset: mulai,
				pageSize: data.pageSize,
				page: data.page,
				nRecords: data.pageSize,
			},
			data: {},
			headers: {
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const pointTransactionApi = (data) => {
	if (data.authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (data.page > 0) {
			mulai = data.pageSize + 1;
			baris = 0;
			for (var i = 0; i <= data.page; ) {
				baris = data.pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/point-transaction",
			params: {
				memberId: data.userId,
				merchantId: data.merchantId,
				trxType: data.trxType,
				memberName: data.memberName,
				username: data.userName,
				refId: data.refId,
				startDate: data.startDate,
				endDate: data.endDate,
				offset: mulai,
				pageSize: data.pageSize,
				page: data.page,
				nRecords: data.pageSize,
				pointType: data.pointType,
				availableDate: data.availableDate,
			},
			data: {},
			headers: {
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const pointTransferApi = (data) => {
	if (data.authToken != null) {
		// var mulai = 0;
		// var baris = 20;

		// if(page>0){
		//     mulai= pageSize + 1;
		//     baris=0;
		//     for(var i=0;i<=page;){
		//         baris = pageSize + baris;
		//         i++;
		//     }
		//     mulai = baris - 20 + 1;
		// }
		return Axios({
			method: "get",
			url: window.ApiURL + "point-transaction/point/transfer/history",
			params: {
				userId: data.userId,
				merchantId: data.merchantId,
				username: data.userName,
				orderBy: "",
				orderType: 1,
				page: data.page,
				nRecords: data.pageSize,
				role: 1,
				startDate: data.startDate,
				endDate: data.endDate,
			},
			data: {},
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const reportPartnerApi = (data) => {
	if (data.authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (data.page > 0) {
			mulai = data.pageSize + 1;
			baris = 0;
			for (var i = 0; i <= data.page; ) {
				baris = data.pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/report-partner",
			params: {
				memberId: data.userId,
				merchantId: data.merchantId,
				memberName: data.memberName,
				productName: data.productName,
				offset: mulai,
				pageSize: data.pageSize,
				page: data.page,
				nRecords: data.pageSize,
				merchantIdPartner: data.merchantIdPartner,
			},
			data: {},
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const pointsTransactionHistoryReportApi = ({
	userId,
	merchantId,
	accountNumber,
	startDate,
	endDate,
	page,
	pageSize,
	authToken,
	deviceId,
}) => {
	// export const pointsTransactionHistoryReportApi = (data) => {
	if (authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (page > 0) {
			mulai = pageSize + 1;
			baris = 0;
			for (var i = 0; i <= page; ) {
				baris = pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/points-transaction-history",
			params: {
				memberId: userId,
				merchantId: merchantId,
				startDate: startDate,
				endDate: endDate,
				offset: mulai,
				pageSize: pageSize,
				page: page,
				nRecords: pageSize,
				rmNumber: accountNumber,
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const pointsVoucherRedemption1ReportApi = ({
	userId,
	merchantId,
	accountNumber,
	startDate,
	endDate,
	page,
	pageSize,
	authToken,
	deviceId,
}) => {
	// export const pointsVoucherRedemption1ReportApi = (data) => {
	if (authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (page > 0) {
			mulai = pageSize + 1;
			baris = 0;
			for (var i = 0; i <= page; ) {
				baris = pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/points-voucher-redemption-one",
			params: {
				memberId: userId,
				merchantId: merchantId,
				startDate: startDate,
				endDate: endDate,
				offset: mulai,
				pageSize: pageSize,
				page: page,
				nRecords: pageSize,
				rmNumber: accountNumber,
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const pointsVoucherRedemption2ReportApi = ({
	userId,
	merchantId,
	accountNumber,
	startDate,
	endDate,
	page,
	pageSize,
	authToken,
	deviceId,
}) => {
	// export const pointsVoucherRedemption2ReportApi = (data) => {
	if (authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (page > 0) {
			mulai = pageSize + 1;
			baris = 0;
			for (var i = 0; i <= page; ) {
				baris = pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/points-voucher-redemption-two",
			params: {
				memberId: userId,
				merchantId: merchantId,
				startDate: startDate,
				endDate: endDate,
				offset: mulai,
				pageSize: pageSize,
				page: page,
				nRecords: pageSize,
				rmNumber: accountNumber,
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};
